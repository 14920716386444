
.navbar {
  width: 100%;
  height: 100px;
  background: #ffffff;
}

.links {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
}

.navbar a {
  color: #f7f200c0;
  text-decoration: none;
  margin: 90px;
  font-size: 35px;
  animation: glitch 1s infinite;

}


@keyframes glitch {
  0%{
      text-shadow: 0.09em 0 0 rgba(255, 210, 137),
      -0.05em -0.025em 0 rgba(244, 172, 50, 0.75), -0.025em 0.05em 0 rgb(253, 231, 76);
  
  }
  14%{
      text-shadow: 0.09em 0 0 rgb(247, 185, 15),
      -0.05em -0.025em 0 rgba(244, 172, 50, 0.75), -0.025em 0.05em 0 rgb(253, 231, 76);
  
  }
  15%{
      text-shadow: -0.05em -0.025em 0 rgba(255, 210, 137),
      0.025em 0.025em 0 rgba(244, 172, 50, 0.75), -0.05em -0.05em 0 rgb(253, 231, 76);
  
  }
  49%{
      text-shadow: -0.05em -0.025em 0 rgba(255, 210, 137),
      0.025em 0.025em 0 rgba(244, 172, 50, 0.75), -0.05em -0.05em 0 rgb(253, 231, 76);
  
  }
  50%{
      text-shadow: 0.025em 0.05em 0 rgba(255, 210, 137),
      0.05em 0 0 rgba(244, 172, 50, 0.75), 0 -0.05em 0 rgb(253, 231, 76);
  
  }
  99%{
      text-shadow: 0.025em 0.05em 0 rgba(255, 210, 137),
      0.05em 0 0 rgba(244, 172, 50, 0.75), 0 -0.05em 0 rgb(253, 231, 76);
  
  }
  100%{
      text-shadow: -0.025em 0 0 rgba(255, 210, 137),
      -0.025em -0.025em 0 rgba(244, 172, 50, 0.75), -0.025em -0.05em 0 rgb(253, 231, 76);
  
  }
}  



.toggleButton {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.toggleButton svg {
  font-size: 50px;
  animation: glitch 1s infinite;
  color: rgb(255, 249, 249);
}

.toggleButton button {
  margin-right: 20px;
  background-color: transparent;
  border: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

#open {
  height: 100vh;
}

#open .links {
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 100px;
}

#open a {
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .navbar a {
    width: 70px;
  }
}

@media only screen and (max-width: 600px) {
  .toggleButton {
    display: flex;
  }
  #close a {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .toggleButton {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }

  #open {
    height: 100px;
  }

  #open .links {
    flex-direction: row;
  }
}
