.about-me {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: whitesmoke;
	
}

.backround-col{
	text-align: center;
	margin-top: 20rem;
	color: whitesmoke;

	
}

#about-head{
  font-weight: 800;
  position: relative;
  overflow: hidden;
  margin: 0;
}

